import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, InputLabel, Menu, MenuItem, OutlinedInput } from '@mui/material'
import { DataGrid, GridActionsCellItem, renderBooleanCell, renderEditBooleanCell } from '@mui/x-data-grid'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import PersonIcon from '@mui/icons-material/Person';
import SearchIcon from '@mui/icons-material/Search';
import { tokens } from '../../theme';
import Header from '../../global/Header';
import { AdminContext } from '../../context/AdminContext';
import UserDialog from './UserDialog';
import { useNavigate } from 'react-router-dom';
import UserSuspensionDialog from './UserSuspensionDialog';
import { Delete, RemoveCircle } from '@mui/icons-material';

function UsersPage() {
    const { admin, token } = useContext(AdminContext);
    const colors = tokens();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(true);
    const [searchKey, setSearchKey] = useState("");
    const [searchId, setSearchId] = useState(null);
    const [orderModel, setOrderModel] = useState(null);
    const [rowCount, setRowCount] = useState(0);
    const [users, setUsers] = useState([]);
    const [userActionState, setUserActionState] = useState({
        targetUser: null,
        actionType: null
    });
    const [editUserDialogOpen, setEditUserDialogOpen] = useState(false);
    const [alertDialogOpen, setAlertDialogOpen] = useState(false);
    const [userSuspensionDialogOpen, setUserSuspensionDialogOpen] = useState(false);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10
    });

    const updateUser = async (userId, user) => {
        var userIx = users.findIndex(u => u.id === userId);
        var profilePhotoLink;
        if (user.photo) {
            await axios.post(`${process.env.REACT_APP_API_URL}webAdmin/uploadUserProfilePhoto?token=${token}`, {
                profilePhoto: user.photo
            }, {
                headers: {
                    "content-type": "multipart/form-data"
                }
            })
                .catch((err) => {
                    console.log("err: " + err);
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {
                        profilePhotoLink = user.profile_photo_link.slice(0, user.profile_photo_link.indexOf("/images") + 1) + response.data.contentPath;
                        user.profile_photo_path = response.data.contentPath;
                    }
                });
        }



        var userToUpdate = { ...user };
        delete userToUpdate.photo;
        delete userToUpdate.profile_photo_link;

        await axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/updateUser?token=${token}&userId=${userId}`, {
            user: userToUpdate
        })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    if (userIx !== null && userIx !== undefined && userIx !== -1) {
                        setUsers(prev => {
                            var newUsers = [...prev];
                            if (profilePhotoLink) {
                                userToUpdate.profile_photo_link = profilePhotoLink;
                            } else {
                                userToUpdate.profile_photo_link = newUsers[userIx].profile_photo_link;
                            }
                            //handle user suspension and unsuspension
                            if (Object.keys(userToUpdate).includes("is_suspended")) {
                                if (prev[userIx].is_truly_suspended === 1) {
                                    userToUpdate.is_truly_suspended = 0;
                                } else {
                                    userToUpdate.is_truly_suspended = 1;
                                }
                            }
                            newUsers[userIx] = { ...newUsers[userIx], ...userToUpdate };
                            return newUsers;
                        })
                    }
                    if (editUserDialogOpen) {
                        setEditUserDialogOpen(false);
                    }
                    if (alertDialogOpen) {
                        setAlertDialogOpen(false);
                    }
                    if (userSuspensionDialogOpen) {
                        setUserSuspensionDialogOpen(false);
                    }
                }
            });
    }

    const columns = [
        { field: 'id', headerName: 'ID' },
        { field: 'profile_photo_link', headerName: "Profil Fotoğrafı", sortable: false, renderCell: (params) => <img src={params.value} alt='profile-pic' style={{ height: "30px", width: "30px" }} />, width: 70 },
        {
            field: 'name',
            headerName: 'İsim'
        }, {
            field: 'surname',
            headerName: 'Soyisim'
        }, {
            field: 'email',
            headerName: 'E-Posta'
        }, {
            field: 'phone_number',
            headerName: 'Telefon No'
        }, {
            field: 'gender',
            headerName: 'Cinsiyet',
            renderCell: (params) => { return params.value === 0 ? "Kadın" : params.value === 1 ? "Erkek" : "Gizli" }
        }, {
            field: 'latitude',
            headerName: 'Enlem'
        }, {
            field: 'longitude',
            headerName: 'Boylam'
        },
        // {
        //     field: 'distance',
        //     headerName: 'Mesafe Filtresi'
        // },
        {
            field: 'is_truly_suspended',
            headerName: 'Açığa Alındı',
            type: "boolean"
        },
        {
            field: 'is_notifiable',
            headerName: 'Bildirim izni',
            type: "boolean"
        }, {
            field: "birth_date", headerName: "Doğum Tarihi",
            renderCell: ({ row: { birth_date } }) => {
                if (!birth_date) return "-";
                return new Date(birth_date).toLocaleDateString()
            }
        }, {
            field: "created_date", headerName: "Kayıt Tarihi",
            renderCell: ({ row: { created_date } }) => {
                return new Date(created_date).toLocaleDateString()
            }
        },
        {
            field: 'actions',
            type: 'actions',
            sortable: false,
            width: 80,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<PersonIcon />}
                    label="Kullanıcıyı Düzenle"
                    onClick={() => setUserActionState({ targetUser: params.row, actionType: "edit" })}
                    showInMenu
                />,
                <GridActionsCellItem
                    icon={<Delete />}
                    label="Kullanıcıyı Sil"
                    onClick={() => setUserActionState({ targetUser: params.row, actionType: "delete" })}
                    showInMenu
                />,
                <GridActionsCellItem
                    icon={<RemoveCircle />}
                    label={
                        (() => {
                            let user = users.find(u => u.id === params.row.id);
                            if (user) {
                                if (user.is_suspended && (user.is_suspended_forever || new Date(user.suspended_until) > new Date())) {
                                    return "Kullanıcıyı Aktifleştir";
                                } else {
                                    return "Kullanıcıyı Açığa Al";
                                }
                            }
                        })()
                    }
                    onClick={() => {
                        let user = users.find(u => u.id === params.row.id);
                        if (user) {
                            if (user.is_suspended && (user.is_suspended_forever || new Date(user.suspended_until) > new Date())) {
                                setUserActionState({ targetUser: params.row, actionType: "unsuspend" })
                            } else {
                                setUserActionState({ targetUser: params.row, actionType: "suspend" })
                            }
                        }
                    }}
                    showInMenu
                />
            ],
        }
    ];

    useEffect(() => {
        setLoading(true);
        axios.get(`${process.env.REACT_APP_API_URL}webAdmin/getUsers?token=${token}&startIndex=${paginationModel.pageSize * paginationModel.page}&limit=${paginationModel.pageSize}${searchKey ? `&searchKey=${searchKey}` : ""}${searchId ? `&searchId=${searchId}` : ""}${orderModel ? `&orderField=${orderModel.field}&orderType=${orderModel.sort}` : ""}`)
            .catch((err) => {
                setUsers([]);
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setUsers(response.data.users);
                    setRowCount((prevRowCount) => response.data.rowCount !== undefined ? response.data.rowCount : prevRowCount);
                }
                setLoading(false);
            });
    }, [paginationModel]);

    useEffect(() => {
        if (userActionState.targetUser) {
            if (userActionState.actionType === "edit") {
                setEditUserDialogOpen(true);
            } else if (userActionState.actionType === "delete" || userActionState.actionType === "unsuspend") {
                setAlertDialogOpen(true);
            } else if (userActionState.actionType === "suspend") {
                setUserSuspensionDialogOpen(true);
            }
        }
    }, [userActionState]);

    const handleEditUserDialogClose = () => {
        setUserActionState({
            targetUser: null,
            actionType: null
        });
        setEditUserDialogOpen(false);
    }

    const handleAlertDialogClose = () => {
        setUserActionState({
            targetUser: null,
            actionType: null
        });
        setAlertDialogOpen(false);
    }

    const handleSuspendDialogClose = () => {
        setUserActionState({
            targetUser: null,
            actionType: null
        });
        setUserSuspensionDialogOpen(false);
    }

    const removeUser = async () => {
        await axios.delete(`${process.env.REACT_APP_API_URL}webAdmin/deleteUser?token=${token}`, {
            data: { userId: userActionState.targetUser.id, firebaseId: userActionState.targetUser.firebase_id }
        })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    setUsers(prev => {
                        return prev.filter(u => u.id !== userActionState.targetUser.id);
                    });
                    setAlertDialogOpen(false);
                }
            });
    }

    return (
        <Box sx={{ p: "75px" }}>
            {userActionState && userActionState.targetUser &&
                <UserDialog
                    dialogOpen={editUserDialogOpen}
                    handleClose={handleEditUserDialogClose}
                    targetUser={userActionState.targetUser}
                    handleFormSubmit={
                        (values) => {
                            updateUser(userActionState.targetUser.id, values)
                        }
                    }
                />
            }
            {
                userActionState && userActionState.targetUser &&
                <Dialog
                    open={alertDialogOpen}
                    keepMounted
                    onClose={handleAlertDialogClose}
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle>
                        {userActionState.actionType === "delete" ? "Kullanıcıyı silmek istediğinizden emin misiniz?" : "Açığa alınan kullanıcıyı aktif hale getirmek istediğinizden emin misiniz?"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {userActionState.actionType === "delete" ? "Kullanıcı ve ilgili tüm veriler silinecek. Bu işlem geri alınamaz." : "Kullanıcı aktif hale getirildiğinde kullanıcı tekrar uygulamayı kullanabilecektir."}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleAlertDialogClose}>İptal</Button>
                        <Button onClick={() => {
                            if (userActionState.actionType === "delete") {
                                removeUser();
                            } else {
                                updateUser(userActionState.targetUser.id, { is_suspended: false, suspended_until: null, is_suspended_forever: false });
                            }
                        }}>
                            {userActionState.actionType === "delete" ? "Sil" : "Aktifleştir"}
                        </Button>
                    </DialogActions>
                </Dialog>
            }
            {
                userActionState && userActionState.targetUser &&
                <UserSuspensionDialog
                    dialogOpen={userSuspensionDialogOpen}
                    handleClose={handleSuspendDialogClose}
                    targetUser={userActionState.targetUser}
                    handleFormSubmit={
                        (values) => {
                            let newValues = { ...values };
                            if (newValues.suspended_until === "") {
                                delete newValues.suspended_until;
                            }
                            console.log(newValues);
                            console.log(userActionState.targetUser.id);
                            updateUser(userActionState.targetUser.id, { ...newValues, is_suspended: true })
                        }
                    }
                />
            }
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center" gap="20px">
                    <Header title="Kullanıcılar" subtitle="Bu sayfadan kullanıcıları görebilir ve yönetebilirsiniz." />
                    <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                        <InputLabel htmlFor="search-box">Ara</InputLabel>
                        <OutlinedInput
                            id="search-box"
                            type='text'
                            onChange={(e) => {
                                setSearchKey(e.target.value ? e.target.value : "");
                            }}
                            label="Ara"
                        />
                    </FormControl>
                    <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                        <InputLabel htmlFor="search-box-id">Ara(Id)</InputLabel>
                        <OutlinedInput
                            id="search-box-id"
                            type='number'
                            onChange={(e) => {
                                setSearchId(e.target.value ? e.target.value : "");
                            }}
                            label="Ara(Id)"
                        />
                    </FormControl>

                    <IconButton
                        aria-label="search button"
                        sx={{ background: colors.grey[900], borderRadius: "10px", padding: "12px" }}
                        onClick={() => { setPaginationModel(prev => { return { ...prev, page: 0 } }) }}
                        edge="end"
                    >
                        <SearchIcon />
                    </IconButton>
                </Box>
            </Box>
            <DataGrid
                rows={users}
                columns={columns}
                rowCount={rowCount}
                loading={isLoading}
                pageSizeOptions={[10, 25, 50, 75, 100]}
                paginationModel={paginationModel}
                paginationMode="server"
                onPaginationModelChange={setPaginationModel}
                sortingMode='server'
                onSortModelChange={(model) => {
                    setOrderModel((prev) => model && model.length > 0 ? model[0] : null);
                    setPaginationModel(prev => { return { ...prev, page: 0 } });
                }}
                onRowDoubleClick={(row) => {
                    // navigate(`/admin-panel/users/${row.id}`);
                }}
                disableColumnFilter
                sx={{
                    ".MuiDataGrid-columnHeaders": {
                        background: colors.grey[900]
                    }
                }}
            />
        </Box>
    )
}

export default UsersPage