import React from 'react'
import { Formik } from 'formik';
import * as yup from "yup";
import { Box, Button, Checkbox, Dialog, DialogContent, TextField, Typography } from '@mui/material';
import { tokens } from '../../../theme';

function UserSuspensionDialog({ targetUser, dialogOpen, handleClose, handleFormSubmit }) {

    const colors = tokens();

    var initialValues = {
        is_suspended: true,
        is_suspended_forever: false,
        suspended_until: (() => {
            var date = new Date(new Date().setDate(new Date().getDate() + 1));
            var dateString = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().split("T")[0];
            return dateString;
        })()
    };

    var validationObject = {
        is_suspended: yup.boolean().required(),
        is_suspended_forever: yup.boolean(),
        suspended_until: yup.date().min(new Date(), "Geçmiş tarih seçilemez.").required("Bu alan zorunludur.").when("is_suspended_forever", {
            is: (is_suspended_forever) => is_suspended_forever,
            then: () => yup.date().nullable()
        })
    }

    const userSchema = yup.object().shape(validationObject);

    return (
        <Dialog
            sx={{ margin: "0 auto" }} open={dialogOpen}
            onClose={handleClose}>
            <Box m="20px" textAlign="center">

                <Typography variant='h3'>
                    Kullanıcıyı Açığa Al
                </Typography>

            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={userSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue, setErrors }) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="grid" gap="15px" gridTemplateColumns="repeat(3, minmax(0, 1fr))" mx="75px" mb="25px">
                                    {
                                        //TODO: Implement form fields
                                    }
                                    <Box display="flex" alignItems="center" gridColumn="span 2">
                                        <Checkbox
                                            name="is_suspended_forever"
                                            checked={values.is_suspended_forever}
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setFieldValue("suspended_until", "");
                                                    setFieldValue("is_suspended_forever", true);
                                                    setErrors({});
                                                } else {
                                                    setFieldValue("suspended_until", (() => {
                                                        var date = new Date(new Date().setDate(new Date().getDate() + 1));
                                                        var dateString = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().split("T")[0];
                                                        return dateString;
                                                    })());
                                                    setFieldValue("is_suspended_forever", false);
                                                }
                                            }}
                                            onBlur={handleBlur}
                                        />
                                        <Typography variant='h5'>Sınırsız süreyle açığa al</Typography>
                                    </Box>
                                    <TextField
                                        name="suspended_until"
                                        type="date"
                                        value={values.suspended_until}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={errors.suspended_until && touched.suspended_until}
                                        helperText={errors.suspended_until && touched.suspended_until ? errors.suspended_until : null}
                                        disabled={values.is_suspended_forever}
                                        sx={{ gridColumn: "span 3" }}
                                    />

                                </Box>
                                <Box display="flex" justifyContent="end" m="20px 20px 0px 20px">
                                    <Button type="submit" color="secondary" variant="contained" onClick={() => console.log(errors)}>
                                        <Typography variant='h5'>Kaydet</Typography>
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog >
    )
}

export default UserSuspensionDialog