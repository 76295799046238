import { Box, FormControl, IconButton, InputLabel, OutlinedInput, Button, Menu, MenuItem, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material'
import { styled } from '@mui/material/styles'
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import PersonIcon from '@mui/icons-material/Person';
import SearchIcon from '@mui/icons-material/Search';
import { tokens } from '../../theme';
import Header from '../../global/Header';
import CompanyDialog from './CompanyDialog';
import { AdminContext } from '../../context/AdminContext';
import { GetApp, Remove } from '@mui/icons-material';
import * as XLSX from 'xlsx';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

function CompaniesPage() {
    const { token } = useContext(AdminContext);
    const colors = tokens();
    const [isLoading, setLoading] = useState(true);
    const [companyDataFile, setCompanyDataFile] = useState(null);
    const [searchKey, setSearchKey] = useState("");
    const [orderModel, setOrderModel] = useState(null);
    const [rowCount, setRowCount] = useState(0);
    const [companies, setCompanies] = useState([]);
    const [targetCompany, setTargetCompany] = useState(null);
    const [editCompanyDialogOpen, setEditCompanyDialogOpen] = useState(false);
    const [alertDialogState, setAlertDialogState] = useState({ open: false, companyId: null });
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10
    });

    const updateCompany = async (companyId, company) => {
        if (!companyId) {
            await axios.post(`${process.env.REACT_APP_API_URL}webAdmin/createCompany?token=${token}`, {
                ...company
            }).catch((err) => {
                console.log("err: " + err);
            })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {
                        company.id = response.data.companyId;
                        setCompanies(prev => [...prev, company]);
                        if (editCompanyDialogOpen) {
                            setEditCompanyDialogOpen(false);
                        }
                        setTargetCompany(null);
                    }
                });
        } else {
            var companyIx = companies.findIndex(c => c.id === companyId);
            await axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/updateCompany?token=${token}&companyId=${companyId}`, {
                company: company
            })
                .catch((err) => {
                    console.log("err: " + err);
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.isUpdated === true && response.data.status === 201) {
                        if (companyIx !== null && companyIx !== undefined && companyIx !== -1) {
                            setCompanies(prev => {
                                var newCompanies = [...prev];
                                newCompanies[companyIx] = { ...newCompanies[companyIx], ...company };
                                return newCompanies;
                            })
                        }
                        if (editCompanyDialogOpen) {
                            setEditCompanyDialogOpen(false);
                        }
                        setTargetCompany(null);
                    }
                });
        }
    }

    const removeCompany = async (companyId) => {
        var companyIx = companies.findIndex(c => c.id === companyId);
        await axios.delete(`${process.env.REACT_APP_API_URL}webAdmin/deleteCompany?token=${token}&companyId=${companyId}`)
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    if (companyIx !== null && companyIx !== undefined && companyIx !== -1) {
                        setCompanies(prev => {
                            var newCompanies = [...prev];
                            newCompanies.splice(companyIx, 1);
                            return newCompanies;
                        })
                    }
                    setAlertDialogState({ open: false, companyId: null });
                }
            });
    }

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            width: 25
        }, {
            field: 'cid',
            headerName: 'cid',
            minWidth: 175
        }, {
            field: 'name',
            headerName: 'İsim'
        }, {
            field: 'category',
            headerName: 'Kategori'
        }, {
            field: 'latitude',
            headerName: 'Enlem'
        }, {
            field: 'longitude',
            headerName: 'Boylam'
        }, {
            field: 'address',
            headerName: 'Adres',
            minWidth: 250
        }, {
            field: 'website',
            headerName: 'Website',
            minWidth: 180
        }, {
            field: 'phone_number',
            headerName: 'Telefon',
        }, {
            field: "created_date", headerName: "Oluşturulma Tarihi",
            renderCell: ({ row: { created_date } }) => {
                return <p style={{ textAlign: "center" }}>{new Date(created_date).toLocaleDateString()}<br />{new Date(created_date).toLocaleTimeString()}</p>
            }
        },
        {
            field: 'actions',
            type: 'actions',
            sortable: false,
            width: 80,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<PersonIcon />}
                    label="Düzenle"
                    onClick={() => setTargetCompany(params.row)}
                    showInMenu
                />,
                <GridActionsCellItem
                    icon={<Remove />}
                    label="Sil"
                    onClick={() => setAlertDialogState({ open: true, companyId: params.row.id })}
                    showInMenu
                />
            ],
        }
    ];

    useEffect(() => {
        if (companyDataFile) {
            axios.post(`${process.env.REACT_APP_API_URL}webAdmin/uploadBulkCompanies?token=${token}`, { companies: companyDataFile }, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).catch((err) => {
                console.log("err: " + err);
                setCompanyDataFile(null);
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    setPaginationModel(prev => { return { ...prev, page: 0 } })
                    setCompanyDataFile(null);
                }
            });
        }
    }, [companyDataFile]);

    useEffect(() => {
        setLoading(true);
        axios.get(`${process.env.REACT_APP_API_URL}webAdmin/getCompanies?token=${token}&startIndex=${paginationModel.pageSize * paginationModel.page}&limit=${paginationModel.pageSize}${searchKey ? `&searchKey=${searchKey}` : ""}${orderModel ? `&orderField=${orderModel.field}&orderType=${orderModel.sort}` : ""}`)
            .catch((err) => {
                setCompanies([]);
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setCompanies(response.data.companies);
                    setRowCount((prevRowCount) => response.data.rowCount !== undefined ? response.data.rowCount : prevRowCount);
                }
                setLoading(false);
            });
    }, [paginationModel]);

    useEffect(() => {
        if (targetCompany) {
            setEditCompanyDialogOpen(true);
        }
    }, [targetCompany]);

    const handleEditCompanyDialogClose = () => {
        setTargetCompany(null);
        setEditCompanyDialogOpen(false);
    }

    const exportCompanies = (fileExtention) => {
        axios.get(`${process.env.REACT_APP_API_URL}webAdmin/getCompanies?token=${token}`)
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    const worksheetApprovers = XLSX.utils.json_to_sheet(response.data.companies);
                    const workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(workbook, worksheetApprovers, "Companies");
                    XLSX.writeFile(workbook, `Companies-${(new Date()).toISOString()}.${fileExtention}`);
                }
            });
    }

    //export menu
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box sx={{ p: "75px" }}>
            <CompanyDialog dialogOpen={editCompanyDialogOpen} handleClose={handleEditCompanyDialogClose} targetCompany={targetCompany} handleFormSubmit={(values) => {
                updateCompany(targetCompany?.id, values);
            }} />
            {
                alertDialogState && alertDialogState.companyId &&
                <Dialog
                    open={alertDialogState.open}
                    keepMounted
                    onClose={() => {
                        setAlertDialogState({ open: false, companyId: null });
                    }}
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle>
                        Şirketi silmek istediğinizden emin misiniz?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Şirket ve ilgili tüm veriler silinecek. Bu işlem geri alınamaz fakat veri tekrar eklenebilir.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            setAlertDialogState({ open: false, companyId: null });
                        }}>
                            İptal
                        </Button>
                        <Button onClick={() => {
                            removeCompany(alertDialogState.companyId);
                        }}>
                            Sil
                        </Button>
                    </DialogActions>
                </Dialog>
            }
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => { exportCompanies("xlsx"); handleClose(); }}>Companies(.xlsx)</MenuItem>
                <MenuItem onClick={() => { exportCompanies("csv"); handleClose(); }}>Companies(.csv)</MenuItem>
            </Menu>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center" gap="20px">
                    <Header title="Şirketler" subtitle="Bu sayfadan şirketleri görüntüleyebilir ve yönetebilirsiniz." />
                    <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                        <InputLabel htmlFor="search-box">Ara</InputLabel>
                        <OutlinedInput
                            id="search-box"
                            type='text'
                            onChange={(e) => {
                                setSearchKey(e.target.value ? e.target.value : "");
                            }}
                            label="Ara"
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    setPaginationModel(prev => { return { ...prev, page: 0 } })
                                } else {
                                    return;
                                }
                            }}
                        />
                    </FormControl>
                    <IconButton
                        aria-label="search button"
                        sx={{ background: colors.grey[900], borderRadius: "10px", padding: "12px" }}
                        onClick={() => { setPaginationModel(prev => { return { ...prev, page: 0 } }) }}
                        edge="end"
                    >
                        <SearchIcon />
                    </IconButton>

                    <Button component="label" variant='contained' color='success' sx={{ ml: "20px" }} onClick={() => {
                        setTargetCompany(null);
                        setEditCompanyDialogOpen(true);
                    }}>
                        Yeni Şirket Ekle
                    </Button>

                    <Button component="label" variant='contained' color='success'>
                        Toplu Şirket Ekle
                        <VisuallyHiddenInput
                            id="company-data-file"
                            name="company-data-file"
                            type="file"
                            accept=".xlsx"
                            onChange={(event) => { console.log(event.target.files[0]); setCompanyDataFile(event.target.files[0]); }}
                        />
                    </Button>
                </Box>

                <IconButton
                    aria-label="export campaigns button"
                    sx={{ background: colors.grey[900], borderRadius: "10px", padding: "12px", mr: "5px" }}
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    edge="end"
                >
                    <GetApp />
                </IconButton>
            </Box>
            <DataGrid
                rows={companies}
                columns={columns}
                rowCount={rowCount}
                loading={isLoading}
                pageSizeOptions={[10, 25, 50, 75, 100]}
                paginationModel={paginationModel}
                paginationMode="server"
                onPaginationModelChange={setPaginationModel}
                sortingMode='server'
                onSortModelChange={(model) => {
                    setOrderModel((prev) => model && model.length > 0 ? model[0] : null);
                    setPaginationModel(prev => { return { ...prev, page: 0 } });
                }}
                onRowDoubleClick={(params) => {
                    setTargetCompany(params.row)
                }}
                showCellVerticalBorder
                showColumnVerticalBorder
                disableColumnFilter
                sx={{
                    ".MuiDataGrid-columnHeaders": {
                        background: colors.grey[900]
                    },
                    ".MuiDataGrid-cell:focus-within": {
                        outline: "none !important;"
                    }
                }}
            />
        </Box>
    )
}

export default CompaniesPage